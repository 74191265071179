<template>
  <b-row class="match-height">
    <b-col lg="12">
      <PricingList />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import PricingList from '@/components/company/pricing/PricingList.vue'

export default {
  components: {
    BRow,
    BCol,
    PricingList,
  },
}
</script>

<style lang="scss">
</style>
